<template>
  <div>
    <template v-if="propMensagens.length < 1">
      <div class="row">
        <div class="col-lg-12">
          <h5>Nenhuma mensasagem cadastrada para essa empresa.</h5>
          <base-button type="primary" class="mx-2" size="sm" @click="adcionaMsgPadrao">Adicionar Padrão</base-button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row my-1">
        <div class="col-lg-1">
          <base-button type="primary" class="mx-2" size="sm" @click="addNovaMsg">Adicionar</base-button>
        </div>
      </div>
      <div class="row my-2" v-for="(item, index) in propMensagens" :key="index">
        <div class="col-lg-9">
          <h5 class="mb-0">{{ item.nm_titulo }} - <small>{{ retornaTipoMsg(item.fl_tipo_mensagem) }}</small></h5>
          {{ item.nm_descricao }}<br>
        </div>
        <div class="col-lg-3">
          <base-button type="warning" class="mx-2" size="sm" @click="editarMsg(item.id_empresa_msg_templates)">Editar</base-button>
          <base-button type="danger" class="mx-2" size="sm" @click="excluirMsg(item.id_empresa_msg_templates)">Excluir</base-button>
        </div>
      </div>
    </template>
    <template v-if="flEditar">
      <div class="row">
        <!-- Titulo -->
        <div class="col-lg-4">
          <label for="titulo">Título:</label>
          <input
            id="titulo"
            ref="titulo"
            type="text"
            class="form-control"
            name="Título:"
            v-model="novaMsg.nm_titulo"
          />
        </div>
        <!-- Descrição -->
        <div class="col-lg-4">
          <label for="descricao">Descrição:</label>
          <input
            id="descricao"
            ref="descricao"
            type="text"
            class="form-control"
            name="Descrição:"
            v-model="novaMsg.nm_descricao"
          />
        </div>
        <!-- Tipo da Mensagem -->
        <div class="col-lg-4">
          <label for="tipoMsg">Tipo da Mensagem</label> <el-select
            ref="tipoMsg"
            name="Tipo da Mensagem"
            class="select-primary"
            id="tipoMsg"
            v-model="novaMsg.fl_tipo_mensagem"
          >
            <el-option
              v-for="tipo in fl_tipo_mensagem"
              :key="tipo.value"
              :value="tipo.value"
              :label="tipo.text"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-12">
          <trumbowyg v-model="novaMsg.tx_mensagem" ref="editorMensagem" :config="config" class="form-control" name="editorMensagem"></trumbowyg>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-auto">
          <base-button type="warning" class="mx-2" size="sm" @click="fecharEditarMsg()">Cancelar</base-button>
          <base-button type="primary" class="mx-2" size="sm" @click="salvaMsg">Salvar</base-button>
        </div>
      </div>
    </template>
    <!-- <pre>{{ novaMsg }}</pre>
    <pre>{{ propMensagens }}</pre> -->
  </div>
</template>

<script>
import { adminApiUrl } from "@/global";
import { Loading, Table, TableColumn, Select, Option, Tooltip, DatePicker } from "element-ui";

//Editor
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
// Plugins are optional
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/langs/pt_br.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/mention/trumbowyg.mention.js'

export default {
  name: "MensagensEmpresa",
  props: {
    propMensagens: { type: Array }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Trumbowyg
  },
  data() {
    return {
      config: {
        lang: 'pt-br',
        autogrow: true,
        removeformatPasted: true,
        tagsToRemove: ['script', 'link'],
        linkTargets: ['_blank'],
        // Adding color plugin button
        btnsAdd: ['foreColor', 'backColor'],
        // Limit toolbar buttons
        btns: [
          ['viewHTML'],
          ['undo', 'redo'],
          ['bold', 'italic'], ['formatting'], ['link'],
          ['foreColor'], ['backColor'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat']
        ]
      },
      msgPadrao: [
        {
          id_empresa: this.$route.params.id,
          fl_tipo_mensagem: "negociacao",
          nm_titulo: "Carta Proposta de Emprego",
          nm_descricao: "Carta com a proposta de emprego para candidatos que chegam na fase de Negociação.",
          tx_mensagem: "<p><strong>Olá %CandidatoNome%, parabéns!</strong></p><p>Estamos felizes em te oferecer o cargo de <strong>%TituloVaga%</strong>.</p><p>Entendemos que você tem a experiência e a atitude que buscamos para fazer parte do nosso time. Esperamos que nossa cultura de desenvolvimento te ajudem a desempenhar as suas habilidades e que tenhamos futuro brilhante pela frente.</p><p>Por favor, reserve um tempo para revisar nossa oferta aqui, isso inclui detalhes importantes sobre sua remuneração, benefícios e os termos e condições de seu emprego na <strong>%NomeEmpresa%</strong>.</p><p>Para aceitar formalmente nossa oferta, siga as etapas abaixo:</p><ul><li><a href=\"%LinkArquivo%\" target=\"_blank\">Leia a Carta Proposta</a></li><li><a href=\"%LinkPortalAceiteProposta%\" target=\"_blank\">Visite o portal online</a></li><li>Clique em continuar para revisar a oferta</li><li>Assine conforme indicado</li><li>Clique em finalizar</li></ul><p>Preste atenção nos detalhes de expiração, se aplicável, em sua carta de oferta.</p><p>Esperamos ter você em nossa equipe!</p><p>Time %NomeEmpresa%<br><a href=\"\\&quot;%EmpresaSite%\\&quot;\" rel=\"\\&quot;noopener\" noreferrer\\\"=\"\" target=\"\\&quot;_blank\\&quot;\">%EmpresaSite%</a></p>"
        },
        {
          id_empresa: this.$route.params.id,
          fl_tipo_mensagem: "desclassificacaoVaga",
          nm_titulo: "Mensagem de aviso de desclassificação",
          nm_descricao: "Feedback e Agradecimento para candidatos desclassificados no processo seletivo",
          tx_mensagem: "<p>Prezado(a) %CandidatoNome%,</p><p>Agradecemos sinceramente o seu interesse na vaga de <strong>%TituloVaga%</strong> na <strong>%NomeEmpresa%</strong>. Ficamos muito impressionados com suas qualificações, experiência e entusiasmo durante o processo seletivo.</p>\r\n<p>Após uma análise cuidadosa de todas as candidaturas, optamos por seguir com outro candidato que, neste momento, se encaixa mais no perfil que buscamos para a vaga.</p><p>Entendemos que receber um feedback negativo pode ser frustrante, mas queremos que saiba que você se destacou durante o processo e que sua participação foi muito valiosa para nós.</p><p>A jornada em busca de um novo desafio profissional pode ser desafiadora, mas acreditamos em seu potencial e o incentivamos a continuar buscando oportunidades e aprimorando suas competências.</p><p>Para te ajudar nesse processo, gostaríamos de compartilhar algumas dicas:</p><ul><li>Continue aprimorando suas habilidades e conhecimentos.</li><li>Participe de eventos e workshops da sua área de interesse.</li><li>Mantenha seu currículo atualizado e explore diferentes plataformas de busca de emprego.</li><li>Fortaleça sua rede de contatos profissionais.</li></ul>\r\n<p>Desejamos-lhe muito sucesso em sua carreira e esperamos poder contar com seu talento em futuras oportunidades.</p><p>Atenciosamente,<br><strong>%NomeEmpresa%</strong><br><a href=\"%EmpresaSite%\">%EmpresaSite%</a></p>"
        },
        {
          id_empresa: this.$route.params.id,
          fl_tipo_mensagem: "posAvaliativo",
          nm_titulo: "Envio de Pesquisa de Satisfação sobre o Processo Seletivo",
          nm_descricao: "Pesquisa para avaliar o processo seletivo que o candidato participou",
          tx_mensagem: "<p>Olá&nbsp;%CandidatoNome%,</p><p>Gostaríamos da sua opnião sobre o processo seletivo que você participou.</p><p>Clique no link a seguir para responder à pesquisa.<br><a href=\"https://www.pesquisa.com.br\" target=\"_blank\">Link</a></p><p>Obrigado,<br><a href=\"%NomeEmpresa%\" target=\"_blank\">%NomeEmpresa%</a><br></p>"
        },
        {
          id_empresa: this.$route.params.id,
          fl_tipo_mensagem: "entrevista",
          nm_titulo: "Convite de Entrevista Padrão",
          nm_descricao: "Convite de Entrevista Padrão para os candidatos",
          tx_mensagem: "<p>Olá,&nbsp;<strong>%CandidatoNome%</strong>,</p><p><strong>Parabéns!</strong> Você avançou no processo de seleção para a vaga&nbsp;<em>%TituloVaga%</em> na empresa&nbsp;<strong>%NomeEmpresa%</strong>.</p><p>A entrevista será <strong>%TipoEntrevista%</strong>&nbsp;e está agendada para <strong>%DataEntrevista%</strong>.<br>Você será entrevistado por:<br><strong style=\"font-size: 1rem;\">%EntrevistadorNome%</strong></p><p>%LocalEntrevista%<br></p><p>%MensagemAdicional%<br></p><p><strong>Boa sorte!!</strong><br>%NomeEmpresa%<br><a href=\"%EmpresaSite%\" target=\"_blank\">%EmpresaSite%</a><br></p>"
        }
      ],
      novaMsg: {
        id_empresa: this.$route.params.id,
        fl_tipo_mensagem: "",
        nm_titulo: "",
        nm_descricao: "",
        tx_mensagem: ""
      },
      fl_tipo_mensagem: [
        { value: 'negociacao', text: 'Carta Proposta de Emprego' },
        { value: 'desclassificacaoVaga', text: 'Desclassificacao' },
        { value: 'posAvaliativo', text: 'Avaliação do Processo Seletivo' },
        { value: 'entrevista', text: 'Convite de Entrevista' }
      ],
      flEditar: false
    }
  },
  methods: {
    retornaTipoMsg(value) {
      const item = this.fl_tipo_mensagem.find(t => t.value === value);
      return item.text;
    },
    editarMsg(value) {
      this.novaMsg = structuredClone(this.propMensagens.find(m => m.id_empresa_msg_templates === value));
      this.flEditar = true;
    },
    fecharEditarMsg() {
      this.novaMsg = {};
      this.resetarNovoMsg();
      this.flEditar = false;
    },
    addNovaMsg() {
      this.novaMsg = {};
      this.resetarNovoMsg();
      this.flEditar = true;
    },
    resetarNovoMsg() {
      this.novaMsg.id_empresa = this.$route.params.id;
      this.novaMsg.fl_tipo_mensagem = "";
      this.novaMsg.nm_titulo = "";
      this.novaMsg.nm_descricao = "";
      this.novaMsg.tx_mensagem = "";
    },
    async adcionaMsgPadrao() {
      await this.$http
        .post('empresa/msgpadrao', this.msgPadrao)
        .then(() => {
          this.notificacao('Mensagens Adicionadas!', 'success');
        })
        .catch(err => {
          this.notificacao('Erro adicionando as mensagens', 'danger');
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    async salvaMsg() {
      if (this.novaMsg.id_empresa_msg_templates !== undefined) {
        this.$delete(this.novaMsg, 'created_at');
        this.$delete(this.novaMsg, 'updated_at');
        this.$delete(this.novaMsg, 'deleted_at');
      }
      await this.$http
        .post(`${adminApiUrl}/empresa/addmsg`, this.novaMsg)
        .then(() => {
          this.notificacao('Mensagens Adicionadas!', 'success');
          this.fecharEditarMsg();
        })
        .catch(err => {
          this.notificacao('Erro adicionando as mensagens', 'danger');
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    async excluirMsg(value) {
      if (confirm('Tem certeza que deseja excluir?')) {
        const data = { id_empresa_msg_templates: value }
        await this.$http
          .post(`${adminApiUrl}/empresa/deletemsg`, data)
          .then(() => {
            this.notificacao('Mensagens Adicionadas!', 'success');
            this.fecharEditarMsg();
          })
          .catch(err => {
            this.notificacao('Erro adicionando as mensagens', 'danger');
            console.log('Erro na API -> ', err);
            return false;
          });
      }

    },
    //Função de notificação
    notificacao(msg, tipo) {
      this.$notify({
        type: tipo,
        message: msg,
        icon: "tim-icons icon-bell-55",
      });
    }
  }
}
</script>

<style>

</style>
